import { debounce } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Particle } from './particle';
import { RandomParticle } from './random-particle';

const PARTICLE_RADIUS = 2;
const PARTICLE_GAP = 3;

export function ParticleHeader() {
  const canvasRef = useRef();
  const particleRef = useRef([]);
  const randomParticleRef = useRef([]);
  const mousePosition = useRef({ x: 0, y: 0 });
  const [canvasWidth, setCanvasWidth] = useState(300);
  const [canvasHeight, setCanvasHeight] = useState(300);

  const buildRandomParticleRefs = (ctx) => {
    const randomParticles = [];
    for (let i = 0; i < 10; i++) {
      randomParticles.push(
        new RandomParticle({
          ctx,
          canvas: canvasRef.current,
          id: `${i}-${Math.random(1000)}`,
          ogImgWidth: canvasWidth,
          color: 'white',
        }),
      );
    }
    randomParticleRef.current.push(...randomParticles);
  };

  const buildParticleRefs = (ctx, pixels) => {
    const particles = [];

    for (let y = 0; y < canvasHeight; y += PARTICLE_GAP) {
      for (let x = 0; x < canvasWidth; x += PARTICLE_GAP) {
        const index = (y * canvasWidth + x) * 4;
        const alpha = pixels[index + 3];
        if (alpha > 0) {
          const red = pixels[index];
          const green = pixels[index + 1];
          const blue = pixels[index + 2];
          const color = `rgb(${red}, ${green}, ${blue})`;
          particles.push(
            new Particle({
              x: x,
              y: y,
              ctx,
              canvasRef,
              id: `${x}-${y}`,
              ogImgWidth: canvasWidth,
              radius: PARTICLE_RADIUS,
              color,
            }),
          );
        }
      }
    }

    particleRef.current.push(...particles);
  };

  const resetCanvasSize = () => {
    const parentNodeWidth =
      canvasRef.current.parentElement?.offsetWidth || canvasWidth;

    const parentNodeHeight = canvasRef.current.parentElement?.offsetHeight;
    const calculatedHeight = parentNodeHeight || canvasHeight;

    if (canvasWidth !== parentNodeWidth) {
      setCanvasWidth(parentNodeWidth);
      setCanvasHeight(calculatedHeight);
    }

    return {
      parentNodeWidth,
      calculatedHeight,
    };
  };

  const loadCanvas = () => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) return;

    const { parentNodeWidth, calculatedHeight } = resetCanvasSize();

    ctx.clearRect(0, 0, parentNodeWidth, calculatedHeight);
    drawText(ctx, parentNodeWidth, calculatedHeight);

    const pixels = ctx.getImageData(0, 0, canvasWidth, canvasHeight).data;
    buildParticleRefs(ctx, pixels);
    buildRandomParticleRefs(ctx);
  };

  const drawText = (ctx, currentCanvasWidth, currentCanvasHeight) => {
    const text = 'Adapt. Flow. Evolve.';
    ctx.fillStyle = 'white';

    // Check viewport width and adjust font size
    let fontSize = 90;
    let textTopMargin = 70;
    if (window.innerWidth < 468) {
      fontSize = 40;
      textTopMargin = 0;
    } else if (window.innerWidth < 768 && window.innerWidth > 468) {
      fontSize = 45;
      textTopMargin = 100;
    } else if (window.innerWidth < 1024 && window.innerWidth > 768) {
      fontSize = 70;
      textTopMargin = 80;
    }

    ctx.font = `bold ${fontSize}px Arial`;

    const textWidth = ctx.measureText(text).width;
    const textX = (currentCanvasWidth - textWidth) / 2;
    const textY = currentCanvasHeight / 2;
    ctx.fillText(text, textX, textY - textTopMargin);
  };

  const drawParticles = (canvasRef) => {
    if (!canvasRef) return;
    particleRef.current.forEach((item) => {
      item.update(mousePosition.current.x, mousePosition.current.y);
    });

    randomParticleRef.current.forEach((rand) => {
      rand.update(mousePosition.current.x, mousePosition.current.y);
    });
  };

  const handleMouseMove = (e) => {
    const canvas = e.target;
    const rect = canvas.getBoundingClientRect();

    mousePosition.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      particleRef.current = [];
      randomParticleRef.current = [];
      resetCanvasSize();
    }, 300);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let animationFrameId;
    const context = canvasRef.current.getContext('2d');

    loadCanvas();
    drawParticles(context, canvasWidth, canvasRef);

    const animate = () => {
      if (!canvasRef.current) return;
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      drawParticles(context, canvasWidth, canvasRef);
      animationFrameId = window.requestAnimationFrame(animate);
    };

    if (!animationFrameId) {
      animate();
    }

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [canvasWidth, canvasHeight]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      onMouseMove={(e) => handleMouseMove(e)}
    ></canvas>
  );
}

export default ParticleHeader;
