export class RandomParticle {
  constructor({ ctx, canvas, id, ogImgWidth, radius, color }) {
    this.x = Math.random() * (canvas?.width || ogImgWidth + 100);
    this.y = Math.random() * (canvas?.height || 600) - canvas?.height;
    this.ctx = ctx;
    this.canvas = canvas;
    this.id = id;
    this.ogImgWidth = ogImgWidth;
    this.radius = radius || 2;
    this.color = color;
    this.velocity = {
      x: 0,
      y: Math.random() * 7,
    };
  }

  update() {
    if (!this.canvas) return;

    // Move particle
    this.x += this.velocity.x;
    this.y += this.velocity.y;

    // If particle is below the canvas, reset its position at the top
    if (this.y - this.radius > this.canvas.height) {
      this.y = 0;
      this.x = Math.random() * (this.canvas?.width || this.ogImgWidth + 100);
    }

    this.draw();
  }

  draw() {
    if (!this.ctx) return;
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    this.ctx.fillStyle = this.color;
    this.ctx.fill();
    this.ctx.closePath();
  }
}
