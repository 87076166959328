export class Particle {
  constructor({ x, y, ctx, canvas, id, ogImgWidth, radius, color }) {
    this.x = x;
    this.y = y;
    this.initialX = Math.random() * (canvas?.width || ogImgWidth + 100);
    this.initialY = Math.random() * (canvas?.height || 600);
    this.initialLoad = true;
    this.originalX = x;
    this.originalY = y;
    this.ctx = ctx;
    this.canvas = canvas;
    this.id = id;
    this.ogImgWidth = ogImgWidth;
    this.radius = radius || 2;
    this.color = color;
    this.velocity = {
      x: (Math.random() - 0.5) * 3,
      y: (Math.random() - 0.5) * 3,
    };
  }

  update(mouseX, mouseY) {
    const dx = this.x - mouseX;
    const dy = this.y - mouseY;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // If the particle is within a certain distance of the mouse cursor, move it away
    if (distance < 100) {
      const angle = Math.atan2(dy, dx);
      this.velocity.x = Math.cos(angle) * (50 / distance);
      this.velocity.y = Math.sin(angle) * (50 / distance);
    } else {
      const originalDx = this.originalX - this.x;
      const originalDy = this.originalY - this.y;
      const originalDistance = Math.sqrt(
        originalDx * originalDx + originalDy * originalDy,
      );

      if (originalDistance > 1) {
        // if the particle is not already at its original position
        const originalAngle = Math.atan2(originalDy, originalDx);
        this.velocity.x = Math.cos(originalAngle) * 0.5;
        this.velocity.y = Math.sin(originalAngle) * 0.5;
      } else {
        // if the particle is at its original position
        this.velocity.x = 0;
        this.velocity.y = 0;
      }
    }

    if (this.initialLoad) {
      this.x = this.initialX;
      this.y = this.initialY;
      this.initialLoad = false;
    } else {
      this.x += this.velocity.x;
      this.y += this.velocity.y;
    }

    this.draw();
  }

  draw() {
    if (!this.ctx) return;
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    this.ctx.strokeStyle = this.color;
    this.ctx.stroke();
    this.ctx.closePath();
  }
}
