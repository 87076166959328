import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../../content/assets/ninja-logo.png';
import NewsLetterComponent from './news-letter';
import '../style.css';
import MobileMenu from './mobile-menu';

import { theme } from '../utils/theme';
import ParticleHeader from './particle-header';
import AnimatedWave from './animated-wave';

const accentColor = theme.colors.accent;
const borderColor = `#ccc`;
const Flex = styled.div`
  display: flex;
`;

const Header = styled.div`
  width: 100%;
  max-width: 1130.5px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;

  img {
    width: 40px;
    margin-bottom: 0;
    margin-right: 12px;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const TwoColLayout = styled.div`
  display: grid;
  grid-template-areas: 'content side-bar';
  grid-template-columns: 10fr 2fr;
  grid-gap: 32px;
  width: 100%;
  padding: 0 16px;
  justify-items: end;
  max-width: 1000px;
  margin: 0 auto;

  @media only screen and (max-width: 900px) {
    grid-template-areas:
      'content'
      'side-bar';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const MainContent = styled.div`
  grid-area: content;
  width: 100%;
`;

const SideBar = styled.div`
  grid-area: side-bar;
  max-width: 400px;
`;

const SideBarContent = styled.div`
  h3 {
    margin: 0 0 16px;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0 0;

  img {
    width: 250px;
  }

  hr {
    width: 200px;
    height: 5px;
    background-color: black;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .copyright {
    margin: 20px;
  }

  @media only screen and (max-width: 600px) {
    padding: 20px 20px 0;
    img {
      width: 200px;
    }
  }
`;

const LogoFont = styled.span`
  font-family: 'signature';
  font-size: 50px;
  text-align: center;
  margin-right: 12px;
  color: white;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Nav = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  font-weight: 400;
  align-items: center;

  a {
    &.active {
      font-weight: 700;
    }

    box-shadow: none;
    color: white;
    text-decoration: none;
    :first-child {
      margin-right: 24px;
    }
  }
`;

const Free = styled.div`
  font-size: 28px;
  line-height: 33px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const SignUpContent = styled.div`
  width: 100%;
  padding: 16px;
  img {
    height: 94.77px;
  }
`;

const SignUpWidget = styled.div`
  max-width: 300px;
  min-width: 250px;
  color: black;
  border: 1px solid ${borderColor};
  border-radius: 5px;
  .accent {
    color: ${accentColor};
    font-weight: 600;
  }
  h3 {
    margin: 0;
    margin-top: 16px;
    font-size: 18px;
    line-height: 22.5px;
    font-weight: 400;
  }
  small {
    line-height: 12px;
    font-weight: 600;
  }
`;

export const GlowButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 2px solid ${accentColor}};
  border-radius: 5px;
  background-color: transparent;
  border-color: ${accentColor};
  cursor: pointer;

  color: ${accentColor};
  padding: 5px 10px;
  transition: 0.2s;

  :hover {
    text-shadow: none;
    color: white;
    animation: none;
    background-color: ${accentColor};
    box-shadow: inset 0px 0px 0.5em 0px ${accentColor},
      0px 0px 0.5em 0px ${accentColor};
  }
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 80vh;
  background: linear-gradient(
    to top,
    rgba(133, 89, 136, 1),
    rgba(107, 73, 132, 1),
    rgba(72, 52, 117, 1),
    rgba(43, 47, 119, 1),
    rgba(20, 24, 82, 1),
    rgba(7, 11, 52, 1)
  );
  @media only screen and (max-width: 600px) {
    height: 200px;
  }
`;

const Container = styled.div`
  background-color: #fff;
`;

const SVGContainer = styled.div`
  width: 100%;
  height: auto;
  svg {
    width: 100%;
    height: auto;
    margin-top: calc(100vw * -0.1);
  }
`;

const Layout = ({ children, pageName, showHero = true }) => {
  return (
    <div>
      <header>
        <HeaderSection>
          <MobileMenu />
          <Header>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              <LogoWrapper>
                <LogoFont>TomDuffy</LogoFont>
              </LogoWrapper>
            </Link>
            <Nav>
              <Link
                to="/"
                activeClassName={pageName === 'home' ? 'active' : ''}
              >
                Articles
              </Link>
              <Link
                to="/about-me"
                activeClassName={pageName === 'about-me' ? 'active' : ''}
              >
                About Me
              </Link>
            </Nav>
          </Header>
          {showHero && <ParticleHeader />}
          {!showHero && (
            <Flex>
              <h1>Edapt . Flow . Evolve </h1>
            </Flex>
          )}
        </HeaderSection>
      </header>
      <main style={{ background: 'white' }}>
        <SVGContainer>
          <AnimatedWave />
        </SVGContainer>
        <Container>
          <div>
            <TwoColLayout>
              <MainContent>{children}</MainContent>
              <SideBar>
                <SideBarContent>
                  <SignUpWidget>
                    <SignUpContent>
                      <Free>
                        <span>Want to Build </span> animations{' '}
                        <span className="accent">like the one above?</span>
                      </Free>

                      <div>
                        <h3>
                          <span>
                            {' '}
                            Sign up and get notified when I release my mini
                            course on{' '}
                            <span className="accent">
                              {' '}
                              building animations with React.
                            </span>
                          </span>
                        </h3>
                      </div>
                    </SignUpContent>
                    <NewsLetterComponent inputOnly />
                  </SignUpWidget>
                </SideBarContent>
              </SideBar>
            </TwoColLayout>
          </div>
        </Container>
      </main>
      <footer>
        <Footer>
          <hr />
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <img src={logo} alt="tomduffytech.com logo" />
          </Link>
          <Link to="/privacy">Privacy Policy</Link>
          <div className="copyright">
            © tomduffytech.com {new Date().getFullYear()}
          </div>
        </Footer>
      </footer>
    </div>
  );
};

export default Layout;
