import React, { useEffect } from 'react';

import { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { GlowButton } from './layout';

const config = {
  apiKey: 'AIzaSyBUKwfhRYjp8tiDC-riTs-BFWG-MpWV-xU',
  authDomain: 'tomduffy-98a72.firebaseapp.com',
  projectId: 'tomduffy-98a72',
};

let db;

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    maxWidth: '400px',
    marginBottom: '18px',
    marginTop: '16px',
    backgroundColor: '#FB8500',
    height: '40px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#FFB703',
    },
    display: 'flex',
    alignItems: 'center',
  },

  input: {
    width: '100%',
    maxWidth: '400px',
    height: '40px',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
}));

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  p {
    text-align: center;
  }

  svg {
    font-size: 80px;
  }
  input {
    width: 100%;
  }

  h3 {
    margin-top: 10px;
  }

  hr {
    width: 200px;
    height: 5px;
    background-color: black;
    margin-top: 10px;
    margin-bottom: 50px;
  }
`;

const ButtonContainter = styled.div`
  margin-top: 20px;
  width: 100%;
`;

function Loader() {
  return (
    <svg
      version="1.1"
      id="L4"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="-25 0 100 100"
      enable-background="new 0 0 0 0"
    >
      <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  );
}

function validateEmail(email) {
  const validate =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}

export default function NewsLetterComponent({ data, inputOnly }) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    import('firebase').then((firebase) => {
      if (firebase && !firebase.apps.length) {
        firebase.initializeApp(config);
      }

      if (firebase) {
        db = firebase.firestore();
      }
    });
  }, []);

  function addEmail(email, e) {
    e.preventDefault();

    const listFields = {
      PATHNAME: `${window.location.pathname}`,
    };

    if (successMessage) {
      setSuccess('Looks like you have already signed up!');
      return;
    }

    if (validateEmail(email)) {
      setLoading(true);
      addToMailchimp(email, listFields)
        .then((res) => {
          setLoading(false);
          setEmail('');
          setSuccess(
            'Success! Check your email for instructions on accessing your course.',
          );
        })
        .catch((e) => {
          setLoading(false);
          setError(
            'Oops there was a problem adding your email. Please try again: ',
          );
        });
    } else {
      setError('Invalid email format.');
    }

    // if (validateEmail(email)) {
    //   if (db && db.collection) {
    //     db.collection("emails")
    //       .add({
    //         email
    //       })
    //       .then(function(docRef) {
    //         setSuccess("Success! Your email has been added!");
    //       })
    //       .catch(function(error) {
    //         setError(
    //           "Oops there was a problem adding your email. Please try again: "
    //         );
    //       });
    //   }
    // } else {
    //   setError("Invalid email format.");
    // }
  }

  const handleEmailInput = (value) => {
    setError('');
    setEmail(value);
  };

  return (
    <ContentContainer>
      {!inputOnly && (
        <>
          <MailOutlineIcon />
          <h3>News Letter</h3>
          <hr />
          <p>
            Don't worry, this newsletter is to keep you current <br /> with the
            coolest articles.
            <strong> No spammy emails, I promise. </strong>
          </p>
        </>
      )}
      {!successMessage && (
        <>
          <OutlinedInput
            className={classes.input}
            placeholder="Email Address"
            onChange={(e) => handleEmailInput(e.target.value)}
            value={email}
          />
          <ButtonContainter>
            <GlowButton onClick={(e) => addEmail(email, e)}>
              {loading ? <Loader /> : 'Subscribe'}
            </GlowButton>
          </ButtonContainter>
        </>
      )}
      <div>
        {error ? (
          <div style={{ color: '#bb3e2b' }}>{error}</div>
        ) : (
          <div style={{ textAlign: 'left', color: '#8ECAE6' }}>
            {successMessage}
          </div>
        )}
      </div>
    </ContentContainer>
  );
}
