import React from 'react';

function AnimatedWave() {
  return (
    <svg
      id="visual"
      viewBox="0 0 960 140"
      width="100%"
      height="90"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <path
        d="M0 72L22.8 73.3C45.7 74.7 91.3 77.3 137 80.5C182.7 83.7 228.3 87.3 274 85.7C319.7 84 365.3 77 411.2 76.7C457 76.3 503 82.7 548.8 84.5C594.7 86.3 640.3 83.7 686 80.7C731.7 77.7 777.3 74.3 823 74.5C868.7 74.7 914.3 78.3 937.2 80.2L960 82L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
        fill="#855988"
      >
        <animate
          attributeName="d"
          repeatCount="indefinite"
          dur="10s"
          values={`
             M0 72L22.8 73.3C45.7 74.7 91.3 77.3 137 80.5C182.7 83.7 228.3 87.3 274 85.7C319.7 84 365.3 77 411.2 76.7C457 76.3 503 82.7 548.8 84.5C594.7 86.3 640.3 83.7 686 80.7C731.7 77.7 777.3 74.3 823 74.5C868.7 74.7 914.3 78.3 937.2 80.2L960 82L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 80L22.8 81.8C45.7 83.7 91.3 87.3 137 85.5C182.7 83.7 228.3 76.3 274 76.8C319.7 77.3 365.3 85.7 411.2 89.8C457 94 503 94 548.8 90C594.7 86 640.3 78 686 78C731.7 78 777.3 86 823 86.2C868.7 86.3 914.3 78.7 937.2 74.8L960 71L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 81L22.8 81C45.7 81 91.3 81 137 81.2C182.7 81.3 228.3 81.7 274 80.3C319.7 79 365.3 76 411.2 74.7C457 73.3 503 73.7 548.8 74.2C594.7 74.7 640.3 75.3 686 78.7C731.7 82 777.3 88 823 90.5C868.7 93 914.3 92 937.2 91.5L960 91L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 72L22.8 73.3C45.7 74.7 91.3 77.3 137 80.5C182.7 83.7 228.3 87.3 274 85.7C319.7 84 365.3 77 411.2 76.7C457 76.3 503 82.7 548.8 84.5C594.7 86.3 640.3 83.7 686 80.7C731.7 77.7 777.3 74.3 823 74.5C868.7 74.7 914.3 78.3 937.2 80.2L960 82L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
          `}
        />
      </path>
      <path
        d="M0 103L22.8 101.7C45.7 100.3 91.3 97.7 137 95.7C182.7 93.7 228.3 92.3 274 91C319.7 89.7 365.3 88.3 411.2 88.7C457 89 503 91 548.8 91.8C594.7 92.7 640.3 92.3 686 93.5C731.7 94.7 777.3 97.3 823 97.8C868.7 98.3 914.3 96.7 937.2 95.8L960 95L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
        fill="#a380a5"
      >
        <animate
          attributeName="d"
          repeatCount="indefinite"
          dur="5s"
          values={`
             M0 103L22.8 101.7C45.7 100.3 91.3 97.7 137 95.7C182.7 93.7 228.3 92.3 274 91C319.7 89.7 365.3 88.3 411.2 88.7C457 89 503 91 548.8 91.8C594.7 92.7 640.3 92.3 686 93.5C731.7 94.7 777.3 97.3 823 97.8C868.7 98.3 914.3 96.7 937.2 95.8L960 95L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 85L22.8 86C45.7 87 91.3 89 137 92C182.7 95 228.3 99 274 100C319.7 101 365.3 99 411.2 96.2C457 93.3 503 89.7 548.8 89.2C594.7 88.7 640.3 91.3 686 93.8C731.7 96.3 777.3 98.7 823 96.5C868.7 94.3 914.3 87.7 937.2 84.3L960 81L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 95L22.8 95.8C45.7 96.7 91.3 98.3 137 99.2C182.7 100 228.3 100 274 97.2C319.7 94.3 365.3 88.7 411.2 87.3C457 86 503 89 548.8 90.2C594.7 91.3 640.3 90.7 686 91.7C731.7 92.7 777.3 95.3 823 95.3C868.7 95.3 914.3 92.7 937.2 91.3L960 90L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 103L22.8 101.7C45.7 100.3 91.3 97.7 137 95.7C182.7 93.7 228.3 92.3 274 91C319.7 89.7 365.3 88.3 411.2 88.7C457 89 503 91 548.8 91.8C594.7 92.7 640.3 92.3 686 93.5C731.7 94.7 777.3 97.3 823 97.8C868.7 98.3 914.3 96.7 937.2 95.8L960 95L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
          `}
        />
      </path>
      <path
        d="M0 99L22.8 100.2C45.7 101.3 91.3 103.7 137 105C182.7 106.3 228.3 106.7 274 105.8C319.7 105 365.3 103 411.2 102.8C457 102.7 503 104.3 548.8 105.5C594.7 106.7 640.3 107.3 686 105.5C731.7 103.7 777.3 99.3 823 100.2C868.7 101 914.3 107 937.2 110L960 113L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
        fill="#c1a9c2"
      >
        <animate
          attributeName="d"
          repeatCount="indefinite"
          dur="8s"
          values={`
             M0 99L22.8 100.2C45.7 101.3 91.3 103.7 137 105C182.7 106.3 228.3 106.7 274 105.8C319.7 105 365.3 103 411.2 102.8C457 102.7 503 104.3 548.8 105.5C594.7 106.7 640.3 107.3 686 105.5C731.7 103.7 777.3 99.3 823 100.2C868.7 101 914.3 107 937.2 110L960 113L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 95L22.8 96.8C45.7 98.7 91.3 102.3 137 105C182.7 107.7 228.3 109.3 274 108.2C319.7 107 365.3 103 411.2 103.3C457 103.7 503 108.3 548.8 110.7C594.7 113 640.3 113 686 113C731.7 113 777.3 113 823 110.7C868.7 108.3 914.3 103.7 937.2 101.3L960 99L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 110L22.8 107.8C45.7 105.7 91.3 101.3 137 101C182.7 100.7 228.3 104.3 274 105.3C319.7 106.3 365.3 104.7 411.2 102.5C457 100.3 503 97.7 548.8 99.5C594.7 101.3 640.3 107.7 686 108.8C731.7 110 777.3 106 823 106C868.7 106 914.3 110 937.2 112L960 114L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 99L22.8 100.2C45.7 101.3 91.3 103.7 137 105C182.7 106.3 228.3 106.7 274 105.8C319.7 105 365.3 103 411.2 102.8C457 102.7 503 104.3 548.8 105.5C594.7 106.7 640.3 107.3 686 105.5C731.7 103.7 777.3 99.3 823 100.2C868.7 101 914.3 107 937.2 110L960 113L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
          `}
        />
      </path>
      <path
        d="M0 106L22.8 108.2C45.7 110.3 91.3 114.7 137 116.3C182.7 118 228.3 117 274 115.5C319.7 114 365.3 112 411.2 110.5C457 109 503 108 548.8 107.5C594.7 107 640.3 107 686 107C731.7 107 777.3 107 823 109.5C868.7 112 914.3 117 937.2 119.5L960 122L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
        fill="#e0d3e0"
      >
        <animate
          attributeName="d"
          repeatCount="indefinite"
          dur="6s"
          values={`
             M0 106L22.8 108.2C45.7 110.3 91.3 114.7 137 116.3C182.7 118 228.3 117 274 115.5C319.7 114 365.3 112 411.2 110.5C457 109 503 108 548.8 107.5C594.7 107 640.3 107 686 107C731.7 107 777.3 107 823 109.5C868.7 112 914.3 117 937.2 119.5L960 122L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 109L22.8 109.2C45.7 109.3 91.3 109.7 137 109.5C182.7 109.3 228.3 108.7 274 109.5C319.7 110.3 365.3 112.7 411.2 113.2C457 113.7 503 112.3 548.8 111.2C594.7 110 640.3 109 686 110.5C731.7 112 777.3 116 823 116.8C868.7 117.7 914.3 115.3 937.2 114.2L960 113L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 112L22.8 113.7C45.7 115.3 91.3 118.7 137 118.8C182.7 119 228.3 116 274 116C319.7 116 365.3 119 411.2 120.2C457 121.3 503 120.7 548.8 120.3C594.7 120 640.3 120 686 118.3C731.7 116.7 777.3 113.3 823 113.7C868.7 114 914.3 118 937.2 120L960 122L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 106L22.8 108.2C45.7 110.3 91.3 114.7 137 116.3C182.7 118 228.3 117 274 115.5C319.7 114 365.3 112 411.2 110.5C457 109 503 108 548.8 107.5C594.7 107 640.3 107 686 107C731.7 107 777.3 107 823 109.5C868.7 112 914.3 117 937.2 119.5L960 122L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
          `}
        />
      </path>
      <path
        d="M0 131L22.8 129.7C45.7 128.3 91.3 125.7 137 125.5C182.7 125.3 228.3 127.7 274 128.3C319.7 129 365.3 128 411.2 126.2C457 124.3 503 121.7 548.8 120.3C594.7 119 640.3 119 686 119.2C731.7 119.3 777.3 119.7 823 120.3C868.7 121 914.3 122 937.2 122.5L960 123L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
        fill="#ffffff"
      >
        <animate
          attributeName="d"
          repeatCount="indefinite"
          dur="3s"
          values={`
             M0 131L22.8 129.7C45.7 128.3 91.3 125.7 137 125.5C182.7 125.3 228.3 127.7 274 128.3C319.7 129 365.3 128 411.2 126.2C457 124.3 503 121.7 548.8 120.3C594.7 119 640.3 119 686 119.2C731.7 119.3 777.3 119.7 823 120.3C868.7 121 914.3 122 937.2 122.5L960 123L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 125L22.8 125.8C45.7 126.7 91.3 128.3 137 128.2C182.7 128 228.3 126 274 125.2C319.7 124.3 365.3 124.7 411.2 124.3C457 124 503 123 548.8 122.8C594.7 122.7 640.3 123.3 686 123.3C731.7 123.3 777.3 122.7 823 123.2C868.7 123.7 914.3 125.3 937.2 126.2L960 127L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 128L22.8 127.7C45.7 127.3 91.3 126.7 137 126.5C182.7 126.3 228.3 126.7 274 127C319.7 127.3 365.3 127.7 411.2 127.8C457 128 503 128 548.8 127.3C594.7 126.7 640.3 125.3 686 125C731.7 124.7 777.3 125.3 823 126.2C868.7 127 914.3 128 937.2 128.5L960 129L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
             M0 131L22.8 129.7C45.7 128.3 91.3 125.7 137 125.5C182.7 125.3 228.3 127.7 274 128.3C319.7 129 365.3 128 411.2 126.2C457 124.3 503 121.7 548.8 120.3C594.7 119 640.3 119 686 119.2C731.7 119.3 777.3 119.7 823 120.3C868.7 121 914.3 122 937.2 122.5L960 123L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z;
          `}
        />
      </path>
    </svg>
  );
}

export default AnimatedWave;

{
  /* <svg
  id="visual"
  viewBox="0 0 960 140"
  width="960"
  height="140"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
>
  <path
    "M0 80L22.8 81.8C45.7 83.7 91.3 87.3 137 85.5C182.7 83.7 228.3 76.3 274 76.8C319.7 77.3 365.3 85.7 411.2 89.8C457 94 503 94 548.8 90C594.7 86 640.3 78 686 78C731.7 78 777.3 86 823 86.2C868.7 86.3 914.3 78.7 937.2 74.8L960 71L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#855988"
  ></path>
  <path
    d="M0 85L22.8 86C45.7 87 91.3 89 137 92C182.7 95 228.3 99 274 100C319.7 101 365.3 99 411.2 96.2C457 93.3 503 89.7 548.8 89.2C594.7 88.7 640.3 91.3 686 93.8C731.7 96.3 777.3 98.7 823 96.5C868.7 94.3 914.3 87.7 937.2 84.3L960 81L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#a380a5"
  ></path>
  <path
    d="M0 95L22.8 96.8C45.7 98.7 91.3 102.3 137 105C182.7 107.7 228.3 109.3 274 108.2C319.7 107 365.3 103 411.2 103.3C457 103.7 503 108.3 548.8 110.7C594.7 113 640.3 113 686 113C731.7 113 777.3 113 823 110.7C868.7 108.3 914.3 103.7 937.2 101.3L960 99L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#c1a9c2"
  ></path>
  <path
    d="M0 109L22.8 109.2C45.7 109.3 91.3 109.7 137 109.5C182.7 109.3 228.3 108.7 274 109.5C319.7 110.3 365.3 112.7 411.2 113.2C457 113.7 503 112.3 548.8 111.2C594.7 110 640.3 109 686 110.5C731.7 112 777.3 116 823 116.8C868.7 117.7 914.3 115.3 937.2 114.2L960 113L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#e0d3e0"
  ></path>
  <path
    d="M0 125L22.8 125.8C45.7 126.7 91.3 128.3 137 128.2C182.7 128 228.3 126 274 125.2C319.7 124.3 365.3 124.7 411.2 124.3C457 124 503 123 548.8 122.8C594.7 122.7 640.3 123.3 686 123.3C731.7 123.3 777.3 122.7 823 123.2C868.7 123.7 914.3 125.3 937.2 126.2L960 127L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#ffffff"
  ></path>
</svg>; */
}

{
  /* <svg
  id="visual"
  viewBox="0 0 960 140"
  width="960"
  height="140"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  version="1.1"
>
  <path
    "M0 81L22.8 81C45.7 81 91.3 81 137 81.2C182.7 81.3 228.3 81.7 274 80.3C319.7 79 365.3 76 411.2 74.7C457 73.3 503 73.7 548.8 74.2C594.7 74.7 640.3 75.3 686 78.7C731.7 82 777.3 88 823 90.5C868.7 93 914.3 92 937.2 91.5L960 91L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#855988"
  ></path>
  <path
    d="M0 95L22.8 95.8C45.7 96.7 91.3 98.3 137 99.2C182.7 100 228.3 100 274 97.2C319.7 94.3 365.3 88.7 411.2 87.3C457 86 503 89 548.8 90.2C594.7 91.3 640.3 90.7 686 91.7C731.7 92.7 777.3 95.3 823 95.3C868.7 95.3 914.3 92.7 937.2 91.3L960 90L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#a380a5"
  ></path>
  <path
    d="M0 110L22.8 107.8C45.7 105.7 91.3 101.3 137 101C182.7 100.7 228.3 104.3 274 105.3C319.7 106.3 365.3 104.7 411.2 102.5C457 100.3 503 97.7 548.8 99.5C594.7 101.3 640.3 107.7 686 108.8C731.7 110 777.3 106 823 106C868.7 106 914.3 110 937.2 112L960 114L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#c1a9c2"
  ></path>
  <path
    d="M0 112L22.8 113.7C45.7 115.3 91.3 118.7 137 118.8C182.7 119 228.3 116 274 116C319.7 116 365.3 119 411.2 120.2C457 121.3 503 120.7 548.8 120.3C594.7 120 640.3 120 686 118.3C731.7 116.7 777.3 113.3 823 113.7C868.7 114 914.3 118 937.2 120L960 122L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#e0d3e0"
  ></path>
  <path
    d="M0 128L22.8 127.7C45.7 127.3 91.3 126.7 137 126.5C182.7 126.3 228.3 126.7 274 127C319.7 127.3 365.3 127.7 411.2 127.8C457 128 503 128 548.8 127.3C594.7 126.7 640.3 125.3 686 125C731.7 124.7 777.3 125.3 823 126.2C868.7 127 914.3 128 937.2 128.5L960 129L960 141L937.2 141C914.3 141 868.7 141 823 141C777.3 141 731.7 141 686 141C640.3 141 594.7 141 548.8 141C503 141 457 141 411.2 141C365.3 141 319.7 141 274 141C228.3 141 182.7 141 137 141C91.3 141 45.7 141 22.8 141L0 141Z"
    fill="#ffffff"
  ></path>
</svg>; */
}
