import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import styles from 'styled-components';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'gatsby';

const MobileWrapper = styles.div`   
    display: flex;
    padding: 20px
    width: 100%;
    height: 40px;
    justify-content: space-between;
    .app-bar {
        width: 100%;
        background-color: ${(props) =>
          props.isScrolled ? 'black' : 'transparent'};
        transition: background-color 0.5s ease;
    
    }
    @media only screen and (min-width: 600px) {
        display: none;
    }

    a {
      text-decoration: none;
      underline: none;
      color: black;
    }
`;

const LogoFont = styles.div`
  font-family: 'signature';
  font-size: 35px;
  text-align: center;
  margin-right: 12px;
  display: flex;
  flex-grow: 1;

  a {
    text-decoration: none;
    color: white;
    box-shadow: none;
  }

`;
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const pageLinks = [
  {
    name: 'Articles',
    link: '/',
  },
  {
    name: 'About Me',
    link: '/about-me',
  },
];

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <MobileWrapper isScrolled={isScrolled}>
      <CssBaseline />
      <AppBar className="app-bar" open={open} elevation={0}>
        <Toolbar>
          <LogoFont>
            <Link to="/">Tom Duffy</Link>
          </LogoFont>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <LogoFont>Tom Duffy</LogoFont>
        </DrawerHeader>
        <Divider />
        <List>
          {pageLinks.map((page, __index) => (
            <Link key={page.link} to={page.link}>
              <ListItem key={page.name} disablePadding>
                <ListItemButton>
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </MobileWrapper>
  );
}
